import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "queue-page" }
const _hoisted_2 = { class: "bg-gray-100" }
const _hoisted_3 = {
  key: 0,
  class: "q-pa-lg error apollo"
}
const _hoisted_4 = { class: "q-pl-sm" }
const _hoisted_5 = {
  key: 1,
  class: "q-pa-lg"
}
const _hoisted_6 = { class: "q-pa-md text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.queryError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_q_banner, {
              rounded: "",
              class: "bg-red-2"
            }, {
              avatar: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "error",
                  color: "red"
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" An error occurred while trying to fetch the client queue: "),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.queryError.message), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.clients.length === 0 && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_q_banner, {
              rounded: "",
              class: "bg-blue-2"
            }, {
              avatar: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: "info",
                  color: "blue"
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" No clients currently checked out ")
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createBlock(_component_q_table, {
            key: 2,
            class: "q-ma-sm",
            rows: _ctx.clients,
            columns: _ctx.table.columns,
            "row-key": "name",
            loading: _ctx.loading,
            "hide-bottom": ""
          }, {
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, {
                class: "cursor-pointer",
                props: props
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table.columns, (col) => {
                    return (_openBlock(), _createBlock(_component_q_td, {
                      key: col.name,
                      onClick: ($event: any) => (_ctx.openClient(props.row.id))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(props.row[col.field]), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128)),
                  _createVNode(_component_q_td, { "auto-width": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        round: "",
                        icon: "check",
                        onClick: _withModifiers(($event: any) => (_ctx.releaseClient(props.row.id)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_tooltip, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Return to queue")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "loading"])),
      (_ctx.clients.length < 3 && !_ctx.loading && _ctx.queueStats.waiting > 0)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 3,
            color: "primary",
            label: "Checkout Client",
            onClick: _ctx.checkoutClient,
            class: "q-mt-md m-4"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", null, "Clients in queue: " + _toDisplayString(_ctx.queueStats.waiting), 1)
    ])
  ]))
}